import {singleton} from "aurelia-framework";

@singleton()
export class CookieConsentService {
  cookieConsentModalComponent;
  functionToResolvePromiseOfNewConsents;

  setCookieConsentModalComponent(cookieConsentModalComponent) {
    this.cookieConsentModalComponent = cookieConsentModalComponent;

    // Has someone previously wanted to open the modal?
    if (this.openModalWhenAttached) {
      this.cookieConsentModalComponent.open(this.consents);
      this.openModalWhenAttached = false
      this.consents = null;
    }
  }

  consentsUpdated(newConsents) {
    // Save
    this._saveConsents(newConsents);

    // Is anyone waiting for the new consents?
    if (this.functionToResolvePromiseOfNewConsents) {
      this.functionToResolvePromiseOfNewConsents(newConsents);
      this.functionToResolvePromiseOfNewConsents = null;
    }
  }


  hasConsent_askIfNecessary(consentType) {
    // Load existing consents
    let consents = this._loadConsents();

    // Do we have consent?
    if (consents[consentType] === true) {
      // Yes
      return Promise.resolve(true);
    } else if (consents[consentType] === false) {
      // No
      return Promise.resolve(false);
    } else if (consents[consentType] === undefined || consents[consentType] === null) {
      // Unknown -> open modal to ask
      return this._askForConsent(consents)
                 .then(newConsents => newConsents[consentType] || false);
    }
  }

  _askForConsent(consents) {
    // If the modal has been attached to the dom, show it
    if (this.cookieConsentModalComponent) {
      this.cookieConsentModalComponent.open(consents);
    }
    // If not, make a note to open it when it is attached
    else {
      this.openModalWhenAttached = true;
      this.consents = consents;
    }

    // Promise to return consents later
    return new Promise(resolve => {
      this.functionToResolvePromiseOfNewConsents = resolve;
    })
  }

  showConsentsModalForEdit() {
    // Load existing consents
    let consents = this._loadConsents();
    this._askForConsent(consents)
        .then(newConsents => {
          // If consents have changed => reload page to remove google analytics
          let oldConsents = this._loadConsents();
          for (let consentsKey in newConsents) {
            if (oldConsents[consentsKey] !== newConsents[consentsKey]) {
              window.location.reload();
              return;
            }
          }
        });
  }

  _loadConsents() {
    // Get json
    let jsonConsents = localStorage.getItem("cookie-consent");

    // If exists: Deserialize
    if (jsonConsents) {
      let loadedConsents = JSON.parse(jsonConsents);
      return loadedConsents;
    }
    // If NOT exists: Insert empty object
    else {
      let newConsents = {};
      this._saveConsents(newConsents);
      return newConsents;
    }
  }

  _saveConsents(consents) {
    let jsonConsents = JSON.stringify(consents);
    localStorage.setItem("cookie-consent", jsonConsents);
  }
}
